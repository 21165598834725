import React from 'react';
import { Container, Heading, Icon, Text } from '@legalshield/adonis-ux-framework';

const QuestionnaireRenderErrorHandler = () => (
  <Container>
    <Container flexbox classNames={['mb-2']} style={{ color: 'var(--r200)' }}>
      <Icon name="feedback_critical" classNames={['mr-2']} />
      <Heading as="T16" text="Application Error" />
    </Container>
    <Container classNames={['ml-1']}>
      <Text text="Something went wrong processing your application. If this error persists, please contact your branch administrator or customer support." />
    </Container>
  </Container>
);

export default QuestionnaireRenderErrorHandler;
