import { z } from 'zod';

import config from '../config';

const flagSchema = z.object({
  SL2414: z.boolean(), // Add CAN compatibility
});

type FeatureFlags = z.infer<typeof flagSchema>;
export const flags: Record<string, FeatureFlags> = {
  [config.LSEnvironment.Dev]: {
    SL2414: true,
  },
  [config.LSEnvironment.Uat]: {
    SL2414: true,
  },
  [config.LSEnvironment.ProductionTest]: {
    SL2414: false,
  },
  [config.LSEnvironment.Production]: {
    SL2414: false,
  },
};
